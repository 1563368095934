import axios from 'axios';

const token = localStorage.getItem('accessToken');
let code = ""
let ticker = ""

if ( localStorage.getItem('codeLanguage') === "" || localStorage.getItem('codeLanguage') === null ) {
    code = "en"
} else {
    code = localStorage.getItem('codeLanguage')
}

if ( localStorage.getItem('instrumentTicker') === "" || localStorage.getItem('instrumentTicker') === null ) {
    ticker = "DNEEUR"
} else {
    ticker = localStorage.getItem('instrumentTicker')
}

const apiPay = axios.create({
    baseURL: process.env.VUE_APP_PAY_API,
    headers: { 'Authorization': `bearer ${token}`, 'Content-Language': code, 'Instrument-Ticker': ticker, 'X-Client-Identifier': "DneToken" }
});

export default apiPay;
<template>
    <div class="row">
        <div class="col-12 col-lg-6 col-xl-5">
            <div class="resume-client skew-right">
                <div class="resume-photo">
                    <div class="photo">
                        <img src="@/assets/images/users/user.svg">
                    </div>

                    <div class="status">
                        <div class="status-email validated"></div>
                        <div class="status-kyc" :class='{pending: $store.state.profile.account_status != "Verified", validated: $store.state.profile.account_status === "Verified"}'></div>
                        <div class="status-phone" :class="{pending: !$store.state.profile.profile_phone_number_verified, validated: $store.state.profile.profile_phone_number_verified}"></div>
                    </div>
                </div>

                <div class="resume-client-cont">
                    <h4>{{$store.state.profile.profile_full_name}}</h4>

                    <div class="status">
                        <div class="status-email validated"></div>
                        <div class="status-kyc" :class='{pending: $store.state.profile.account_status != "Verified", validated: $store.state.profile.account_status === "Verified"}'></div>
                        <div class="status-phone" :class="{pending: !$store.state.profile.profile_phone_number_verified, validated: $store.state.profile.profile_phone_number_verified}"></div>
                        
                        <span class="btn-validate" @click="modalPhoneValidate = true" v-if="!$store.state.profile.profile_phone_number_verified">
                            {{ getMyLanguage("client-space","client-space.account.lbl.phone.validate.btn") }}
                        </span>
                    </div>

                    <div class="resume-infs">
                        <p>{{ maskedEmail($store.state.profile.profile_email) }}</p>

                        <p :class="{invalid: !$store.state.profile.profile_phone_number_verified}">
                            +{{$store.state.profile.profile_phone_country_ddi}} {{ maskedPhone($store.state.profile.profile_phone_number) }}

                            <span class="btn-validate" @click="modalPhoneValidate = true" v-if="!$store.state.profile.profile_phone_number_verified">
                                {{ getMyLanguage("client-space","client-space.account.lbl.phone.validate.btn") }}
                            </span>
                        </p>

                        <p v-if="showCountry">{{ countryTranslate(dataCountry.filter(country => country.Name == $store.state.profile.profile_country_name)[0].IsoCode) }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-6 col-xl-4">
            <div class="client-referer-invite skew-right">
                <div class="load" v-if="!showContent"></div>

                <div class="cont" v-if="showContent">
                    <!-- <h4 v-if="$store.state.phaseCurrent.phase_type == 'Private'">{{getMyLanguage("client-space","client-space.bonus-invite-title")}}</h4>
                    <h4  v-if="$store.state.phaseCurrent.phase_type == 'Public'">ICO <small>(Public sale)</small></h4> -->

                    <!-- <p v-if="!$store.state.profile.is_agent && $store.state.phaseCurrent.phase_type == 'Private'">{{getMyLanguage("client-space","client-space.bonus-invite-private-cta")}}</p>
                    <p v-if="$store.state.profile.is_agent && $store.state.phaseCurrent.phase_type == 'Private'">{{getMyLanguage("client-space","client-space.bonus-invite-private")}}</p> -->
                    <!-- <p v-if="$store.state.phaseCurrent.phase_type != 'Private'">{{getMyLanguage("client-space","client-space.bonus-invite-txt")}}</p> -->
                    
                    <p>{{getMyLanguage("client-space","client-space.bonus-invite-txt")}}</p>

                    <div class="bts-invite">
                        <div v-if="($store.state.phaseCurrent.phase_type == 'Private') && !$store.state.profile.is_agent" class="btn-secondary" @click="modalBecomeSeller = true">
                            {{getMyLanguage("client-space","client-space.become-seller-cta")}}
                        </div>

                        <div v-if="($store.state.phaseCurrent.phase_type == 'Private') && $store.state.profile.is_agent" class="btn-secondary" id="generateInvite" @click="openInvite()">
                            {{getMyLanguage("client-space","client-space.bonus-invite-cta")}}
                        </div>
                        
                        <div class="btn-url-copy" @click="copyURLRegister()">
                            <span>{{getMyLanguage("client-space","client-space.register-link")}}</span>
                        </div>
                    </div>

                    <a target="_blank" class="btn-more"
                        :href='"https://cdn.dneecosystem.com/docs/marketing/DNE-Presentation-Seller-" + flagSelected + ".pdf?v=" + Math.floor(Math.random() * 99999)'>
                        
                        <span>{{getMyLanguage("client-space","client-space.bonus-invite.bt-more")}}</span>
                    </a>
                    
                    <!-- <a target="_blank" class="btn-more"
                        v-if="$store.state.phaseCurrent.phase_type == 'Private'"
                        :href='"https://cdn.dneecosystem.com/docs/marketing/DNE-Presentation-Seller-" + flagSelected + ".pdf?v=" + Math.floor(Math.random() * 99999)'>
                        
                        <span>{{getMyLanguage("client-space","client-space.bonus-invite.bt-more")}}</span>
                    </a>

                    <a target="_blank" class="btn-more"
                        v-else
                        :href='"https://cdn.dneecosystem.com/docs/marketing/DNE-Presentation-Seller-ico-" + flagSelected + ".pdf?v=" + Math.floor(Math.random() * 99999)'>
                        
                        <span>{{getMyLanguage("client-space","client-space.bonus-invite.bt-more")}}</span>
                    </a> -->

                </div>
            </div>
        </div>
        
        <div class="col-12 col-xl-3 client-countdown">
            <ClientSpaceCountdown />
        </div>


        <!-- MODAL PHONE VALIDATE -->
        <div class="modal" v-if="modalPhoneValidate">
            <div class="modal-cont">
                <div class="modal-close" @click="modalPhoneValidate = false"></div>
                
                <h2 class="title-secondary">{{getMyLanguage("client-space","client-space.account.lbl.phone.validate")}}</h2>
                <p class="step-validate" v-if="!flowSendSMS.success && !errors.flow" v-html='getMyLanguage("client-space","client-space.account.lbl.phone.validate-step01")'></p>
                
                <div class="load" v-if="flowSendSMS.load"></div>

                <!-- SMS CODE -->
                <div class="sms-code" v-if="!flowSendSMS.success && !errors.flow">                        
                    <div class="btn-primary getCode" v-if="!flowSendSMS.smsCodeShow && !flowSendSMS.load" @click="getCodeSend()">{{getMyLanguage("wallets","modal-send-code.btn-generate")}}</div>

                    <div class="sms-code-cont">
                        <div class="invalid-code" v-if="errors.invalidCode">{{getMyLanguage("client-space","client-space.account.lbl.phone.validate-error")}}</div>

                        <div class="sms-confirm" :class="{errorCode: errors.invalidCode}" v-if="flowSendSMS.smsCodeShow && !flowSendSMS.load">
                            <input type="text" class="form form-smsCode" id="smsCodeForm" v-model="codeSMS.code" v-mask="'000000'" placeholder="______" inputmode="numeric" autocomplete="one-time-code">
                            <div class="confirm btn-disabled" v-if="codeSMS.code.length < 6">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                            <div class="confirm" v-if="codeSMS.code.length == 6" @click="validateCodeSMS()">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                        </div>
                        
                        <div class="alert" v-if="flowSendSMS.smsCodeShow && !flowSendSMS.load">
                            {{getMyLanguage("wallets","modal-send-code.msg-sms")}} +{{$store.state.profile.profile_phone_country_ddi}} {{ maskedPhone($store.state.profile.profile_phone_number) }}
                        </div>
                        <!-- <div class="load" v-if="flowSendSMS.smsCodeShow && flowSendSMS.load"></div> -->
                    </div>


                    <div class="resend" v-if="flowSendSMS.smsCodeShow && !flowSendSMS.load">
                        <div class="btn-resend resend-load" v-if="!flowSendSMS.btnResendSMS">{{getMyLanguage("wallets","modal-send-code.btn-resend-in")}} {{flowSendSMS.countDownResendCode}}s</div>
                        <div class="btn-resend" v-if="flowSendSMS.btnResendSMS" @click="getCodeSend()">{{getMyLanguage("wallets","modal-send-code.btn-resend")}}</div>
                    </div>
                </div>


                <!-- BOX SUCCESS -->
                <div class="box-success" v-if="flowSendSMS.success">
                    <p v-html='getMyLanguage("client-space","client-space.account.lbl.phone.validate-ok")'></p>
                </div>


                <!-- BOX ERROR -->
                <div class="box-error" v-if="errors.flow != false">
                    <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                    <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                    <div class="cod-error">
                        Cód.: {{errors.flow}}
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- MODAL BECOME SELLER -->
    <div class="modal modal-become-seller" v-if="modalBecomeSeller">
        <div class="modal-cont">
            <div class="modal-close" @click="modalBecomeSeller = false"></div>
            <h2 class="title-secondary">{{getMyLanguage("client-space","client-space.become-seller-cta")}}</h2>

            <div class="modal-scroll">
            <p>Quando uma moeda digital lança a sua pré-venda, a primeira fase é designada como venda privada. A venda privada envolve a venda de moedas a um preço mais baixo a um grupo específico de indivíduos ou entidades. As vendas privadas são mantidas em segredo em relação ao público em geral.</p>
            <p><strong>DNE Box 20:</strong> O utilizador adquire 90 Euros em DNE Token para fazer staking por 12 meses e recebe um bónus imediato de 20% para usar como preferir em todo o nosso Ecossistema DNE (compras online ou em lojas físicas);</p>
            <p>Nesta fase, o primeiro benefício que terá é a aquisição do DNE Token a um preço inferior ao seu valor de lançamento. Oferecemos um desconto de 40% na compra de Lotes de DNE Token</p>
            <p>&nbsp;</p>
            <p><strong>Regras para a aquisição de Lotes de DNE Token:</strong></p>
            <ul>
                <li>• Staking durante 18 meses;</li>
                <li>• Compra Mínima de 10 Lotes;</li>
                <li>• Compra Máxima de 250 Lotes por pessoa;</li>
                <li>• Compra Máxima de 500 Lotes por entidade.</li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>Outros benefícios incluem:</strong></p>
            <ul>
                <li>• Comissão de venda direta de 10%;</li>
                <li>• Após realizar 5 vendas, começará a receber 3% sobre as vendas efetuadas pelas pessoas que indicou.</li>
            </ul>
            </div>

            <div class="bts">
                <a :href="`/${flagSelected}/client-space/become-seller`" class="btn-primary">Tornar vendedor</a>
                <div class="btn-tertiary">Ainda não</div>
            </div>
        </div>
    </div>


    <!-- MODAL INVITE -->
    <div class="modal modal-invite" v-if="modalInvite.modal">
        <div class="modal-cont">
            <div @click='closeInvite()' class="modal-close"></div>
            
            <h2 class="title-secondary">{{ getMyLanguage("client-space", "modalInvite.title") }}</h2>
            <!-- <div class="instructions">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Neque dolorum minus quam possimus adipisci ipsam, culpa, quia fuga quis hic voluptatum eveniet facilis earum.</div> -->

            <!-- HAS INVITE -->
            <div class="box has-invite">
                <div v-if="!dataInvite">
                    <label>
                        {{ getMyLanguage("client-space","modalInvite.invitationID") }}
                        <input type="text" class="form" v-model="dataGenerateInvite.name" :placeholder='getMyLanguage("client-space","modalInvite.invitation-placeholder")'>
                    </label>
                        
                    <div class="bts-invite" v-if="dataGenerateInvite.name.length < 5">
                        <div class="btn btn-disable">{{getMyLanguage("client-space","modalInvite.generate.customer")}}</div>
                        <div class="btn btn-disable">{{getMyLanguage("client-space","modalInvite.generate.company")}}</div>
                    </div>

                    <div class="bts-invite" v-if="dataGenerateInvite.name.length >= 5">
                        <div class="btn" :class='{active: dataGenerateInvite.invite_type === "account_invite"}' @click='(dataGenerateInvite.invite_type = "account_invite"),(modalInvite.invite),(newInvite(dataGenerateInvite.invite_type))'>
                            {{getMyLanguage("client-space","modalInvite.generate.customer")}}
                        </div>
                        
                        <div class="btn" :class='{active: dataGenerateInvite.invite_type === "company_invite"}' @click='(dataGenerateInvite.invite_type = "company_invite"),(modalInvite.invite),(newInvite(dataGenerateInvite.invite_type))'>
                            {{getMyLanguage("client-space","modalInvite.generate.company")}}
                        </div>
                    </div>
                </div>

                <div v-if="dataInvite">
                    <div class="generated-invitation">
                        <p>{{ getMyLanguage("client-space", "modalInvite.copy-instructions") }}</p>
                    </div>

                    <label>
                        {{ getMyLanguage("register", "register.label.share") }}
                        <div class="share" @click="copyShare(dataInvite.link)"><span>{{dataInvite.link}}</span></div>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import apiPlatform from '@/config/apiPlatform.js'
    import apiInternational from '@/config/apiInternational.js'

    import getMyContentLanguage from "@/services/contentLanguage.js";
    import countriesList from "@/services/listCountries.js";
    
    import ClientSpaceCountdown from '@/components/ClientSpaceCountdown.vue';

    export default {
        components: {
            ClientSpaceCountdown
        },

        data(){
            return{
                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,
                VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,
                flagSelected: "en",
                showContent: false,
                showCountry: false,
                
                modalPhoneValidate: false,
                modalBecomeSeller: false,

                codeSMS: {
                    code: ''
                },

                flowSendSMS: {
                    load: false,
                    smsCodeShow: false,
                    btnResendSMS: false,
                    success: false,
                    countDownResendCode: 0
                },

                errors: {
                    flow: false,
                    invalidCode: false
                },
                    
                dataInvite: [],
                
                modalInvite: {
                    modal: false,
                    invite: false,
                },
                
                dataGenerateInvite: {
                    name: "",
                    invite_type: ""
                }
            }
        },

        async mounted(){
            const tokenValue = localStorage.getItem('accessToken')

            if ( !tokenValue ){
                localStorage.setItem("accessToken","logged out")
                window.location.href = `/${this.flagSelected}/account/login`
            }


            setTimeout(() => {
                this.showContent = true
            }, 1500);
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },


            // COUNTRIES
            countryTranslate(code){
                return countriesList(code, this.$store.state.SelectLanguage.code)
            },

            ordercountries() {
                this.countriesTranslated = [...this.$store.state.allCountries];
                
                this.countriesTranslated.forEach(lang => {
                    lang.translatedCountry = this.countryTranslate(lang.IsoCode);
                });

                this.countriesTranslated.sort((a, b) => {
                    return a.translatedCountry.toLowerCase().localeCompare(b.translatedCountry.toLowerCase());
                });
            },


            maskedEmail(email) {
                if (!email) return '';
                const [localPart, domain] = email.split('@');
                if (!localPart || !domain) return email;
                const visibleStart = localPart.slice(0, 2);
                const maskedLocalPart = `${visibleStart}****`;
                return `${maskedLocalPart}@${domain}`;
            },


            maskedPhone(phoneNumber) {
                if (!phoneNumber) return ''
                const visibleStart = phoneNumber.slice(0, 2)
                const visibleEnd = phoneNumber.slice(-2)

                return `${visibleStart}****${visibleEnd}`
            },


            async getCodeSend(){
                this.flowSendSMS.smsCodeShow = false
                this.flowSendSMS.load = true
                this.errors.invalidCode = false
                this.codeSMS.code = ''

                await apiPlatform.get('/api/v1/platform/security/phone/validate')
                .then(response => {
                    console.log(response)
                    console.clear()

                    setTimeout(() => {
                        this.flowSendSMS.smsCodeShow = true
                        this.flowSendSMS.load = false
                        this.flowSendSMS.btnResendSMS = false

                        this.flowSendSMS.countDownResendCode = 15
                        this.countDownFlowSend()
                    }, 300);

                    setTimeout(() => {
                        document.getElementById('smsCodeForm').focus()
                    }, 400);
                })
                .catch(error => {
                    setTimeout(() => {
                        // this.errors.flow = error.response.status

                        this.errors.flow = "500 (invalid phone)"
                        this.flowSendSMS.load = false
                        
                        console.log(error)
                    }, 500);

                    setTimeout(() => {
                        this.errors.flow = false
                        this.flowSendSMS.load = false
                    }, 5000);
                })
            },


            countDownFlowSend() {
                if(this.flowSendSMS.countDownResendCode > 0) {
                    setTimeout(() => {
                        this.flowSendSMS.countDownResendCode -= 1
                        this.countDownFlowSend()
                    }, 1000)
                } else{
                    this.flowSendSMS.btnResendSMS = true
                }
            },


            async validateCodeSMS(){
                this.flowSendSMS.load = true
                
                await apiPlatform.post('/api/v1/platform/security/phone/validate', this.codeSMS)
                .then(response => {
                    console.log(response)
                    console.clear()

                    setTimeout(() => {
                        this.flowSendSMS.success = true
                        this.flowSendSMS.load = false
                        this.errors.invalidCode = false

                        this.$store.commit('validatePhoneNumber', true)
                    }, 1000);
                })
                .catch(error => {
                    if ( error.response.status === 400 ){
                        if ( error.response.data === "phone_number_invalid_code" ){
                            this.errors.invalidCode = true
                            this.flowSendSMS.load = false
                        } else{
                            this.errors.flow = error.response.status
                        }
                    }
                    
                    this.flowSendSMS.load = false

                    setTimeout(() => {
                        this.errors.flow = false
                    }, 5000);
                })
            },


            // MODAL INVITE
            openInvite(){
                this.menu = false
                this.modalInvite.modal = true
                this.dataInvite = ""
                this.dataGenerateInvite.name = ""
                this.dataGenerateInvite.invite_type = ""

                setTimeout(() => {
                    var modalClose = document.querySelector('.invites-page .modal-invite .modal-close');
                    
                    if ( modalClose ){
                        modalClose.classList.add('close-refresh');
                    }
                    
                }, 1000);
            },

            closeInvite(){
                this.modalInvite.modal = false
                this.dataGenerateInvite.invite_type = ""

                const modalClose = document.querySelector('.modal-invite .modal-close');
                
                if (modalClose.classList.contains('close-refresh')) {
                    window.location.reload();
                }
            },

            async newInvite(){
                this.showContent = false
                this.menu = false

                const resumeInvite = await apiInternational.post('/api/v2/sales/agent/invite', this.dataGenerateInvite)
                this.dataInvite = resumeInvite.data

                this.showContent = true
            },

            async copyURLRegister(){
                try {
                    // await navigator.clipboard.writeText(`${this.VUE_APP_INTERNATIONAL_URL}${this.flagSelected}/ico?utm_content=${this.$store.state.profile.account_id}&utm_source=dne-app&utm_campaign=ico`);
                    await navigator.clipboard.writeText(`${this.VUE_APP_PLATFORM_URL}${this.flagSelected}/account/register?utm_content=${this.$store.state.profile.account_id}&utm_source=dne-app&utm_campaign=box`);
                    this.openToastCopy()
                }
                
                catch($e) {
                    this.openToastNoCopy()
                }
            },

            async copyShare(urlShareInvite){
                try {
                await navigator.clipboard.writeText(urlShareInvite);
                    this.openToastCopy()
                }
                
                catch($e) {
                    this.openToastNoCopy()
                }
            },

            async openToastCopy() {
                this.$toast.success(this.getMyLanguage("client-space","instructions.copied"));
                setTimeout(this.$toast.clear, 6000)
            },
            
            
            async openToastNocopy() {
                this.$toast.success(this.getMyLanguage("client-space","instructions.nocopied"));
                setTimeout(this.$toast.clear, 6000)
            }

            // async copyInvite() {
            //     try {

            //         console.log(this.$store.state.phaseCurrent.phase_type)
                    
            //         if ( this.$store.state.phaseCurrent.phase_type == "Private" ){
            //             await navigator.clipboard.writeText(`${this.VUE_APP_PLATFORM_URL}${this.flagSelected}/account/lead/?referrer=${this.$store.state.profile.account_id}`);
            //         } else {
            //             await navigator.clipboard.writeText(`${this.VUE_APP_PLATFORM_URL}${this.flagSelected}/account/register/?referrer=${this.$store.state.profile.account_id}`);
            //         }
                    
            //         this.$toast.success(this.getMyLanguage("client-space", "instructions.copied"));
            //         setTimeout(this.$toast.clear, 3000);    
            //     }
                
            //     catch ($e) {
            //         this.$toast.success(this.getMyLanguage("client-space", "instructions.nocopied"));
            //         setTimeout(this.$toast.clear, 3000);
            //     }
            // }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                    this.ordercountries()
                }
            },
            'this.$store.state.allCountries': {
                immediate: true,
                handler() {
                    setTimeout(() => {
                        this.dataCountry = this.$store.state.allCountries
                        this.showCountry = true
                    }, 1500);
                }
            }
        }
    }
</script>

<style src="@/assets/scss/_clientSpaceResume.scss" lang="scss" scoped />